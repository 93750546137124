<template>
	<section class="seo">
		<ZyroSettingsTitle>
			{{ $t('siteSettings.nav.seo') }}
		</ZyroSettingsTitle>
		<div class="seo__container">
			<div class="seo__info">
				<ZyroText
					v-for="(infoText, index) in $t('siteSettings.seo.infoTexts')"
					:key="`${index}-infotext`"
				>
					<template #title>
						{{ infoText.title }}
					</template>
					<template #text>
						{{ infoText.text }}
					</template>
				</ZyroText>
			</div>
			<div
				class="seo__meta"
				data-qa="sitesettings-seo-list"
			>
				<ZyroCollapsible
					v-for="(page, key, index) in defaultPages"
					:key="`${index}-seo-meta`"
					v-qa="`sitesettings-seo-pagetitle-${page.name}`"
					:icon="NAVIGATION_ICONS[
						key === website.meta.homepageId ? NAVIGATION_HOMEPAGE_TYPE : NAVIGATION_PAGE_TYPE
					].icon"
					:is-open="isOpen[index]"
					@toggle="isOpen.splice(index, 1, !isOpen[index])"
				>
					<template #heading>
						{{ page.name }}
					</template>
					<template #tag>
						<ZyroStatus
							:status-done="!!inputValues[key].status"
							:text-done="inputValues[key].status"
						/>
					</template>
					<template #content>
						<ZyroFieldText
							:data-qa="`sitesettings-seo-input-title-${page.name}`"
							:value="inputValues[key].title"
							:placeholder="$t('siteSettings.seo.textFields.title.placeholder')"
							:label="$t('siteSettings.seo.textFields.title.label')"
							:info-text="$t('siteSettings.seo.textFields.title.infoText')"
							label-theme="secondary"
							input-theme="secondary"
							maxlength="60"
							@input="$v.inputValues[key].$touch(), inputValues[key].title = $event.target.value"
						/>
						<ZyroFieldText
							:data-qa="`sitesettings-seo-input-description-${page.name}`"
							:label="$t('siteSettings.seo.textFields.description.label')"
							label-theme="secondary"
							input-theme="secondary"
							:info-text="$t('siteSettings.seo.textFields.description.infoText')"
							:placeholder="$t('siteSettings.seo.textFields.description.placeholder')"
							input-tag="textarea"
							:value="inputValues[key].description"
							maxlength="160"
							@input="
								$v.inputValues[key].$touch(),
								inputValues[key].description = $event.target.value"
						/>
					</template>
					<template
						v-if="$v.inputValues[key].$anyDirty"
						#footer
					>
						<Transition name="fade">
							<ZyroButton
								v-qa="`sitesettings-seo-btn-cancel-${page.name}`"
								theme="plain"
								class="seo__meta-footer"
								@click="resetKey = key, activeModalName = 'confirm'"
							>
								{{ $t('common.cancel') }}
							</ZyroButton>
						</Transition>
						<ZyroButton
							v-qa="`sitesettings-seo-btn-save-${page.name}`"
							theme="primary"
							@click="submit(key, index)"
						>
							{{ $t('common.saveChanges') }}
						</ZyroButton>
					</template>
				</ZyroCollapsible>
			</div>
		</div>
		<SiteSettingsModal
			v-if="activeModalName === 'confirm'"
			:title="$t('siteSettings.common.unsavedChanges')"
			:right-button-text="$t('common.discard')"
			:left-button-text="$t('common.continueEditing')"
			@close="activeModalName = ''"
			@left-button-click="activeModalName = ''"
			@right-button-click="activeModalName = '', reset(), resetKey = null;"
		>
			{{ $t('siteSettings.common.unsavedChangesText') }}
		</SiteSettingsModal>
	</section>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import SiteSettingsModal from '@/components/site-settings/components/SiteSettingsModal.vue';
import ZyroCollapsible from '@/components/site-settings/components/ZyroCollapsible.vue';
import ZyroSettingsTitle from '@/components/site-settings/components/ZyroSettingsTitle.vue';
import ZyroStatus from '@/components/site-settings/components/ZyroStatus.vue';
import ZyroText from '@/components/site-settings/components/ZyroText.vue';
import {
	NAVIGATION_ICONS,
	NAVIGATION_PAGE_TYPE,
	NAVIGATION_HOMEPAGE_TYPE,
} from '@/use/navigation/constants';

export default {
	components: {
		ZyroSettingsTitle,
		ZyroText,
		ZyroCollapsible,
		ZyroStatus,
		SiteSettingsModal,
	},
	setup() {
		return {
			NAVIGATION_ICONS,
			NAVIGATION_PAGE_TYPE,
			NAVIGATION_HOMEPAGE_TYPE,
		};
	},
	data() {
		return {
			inputValues: {},
			activeModalName: '',
			resetKey: null,
			isOpen: [],
		};
	},
	validations() {
		return {
			inputValues: Object.keys(this.inputValues).reduce((accumulator, item) => ({
				...accumulator,
				[item]: {
					title: {},
					description: {},
				},
			}), {}),
		};
	},
	computed: {
		...mapState(['website']),
		...mapGetters('pages', ['defaultPages']),
	},
	beforeMount() {
		this.isOpen = new Array(Object.keys(this.website.pages).length).fill(false);
		Object.keys(this.defaultPages).forEach((key) => {
			const title = this.getWebsiteMetaProperty(key, 'title');
			const description = this.getWebsiteMetaProperty(key, 'description');

			this.inputValues[key] = {
				title,
				description,
				status: title && description ? this.$t('common.completed') : '',
			};
		});
	},
	methods: {
		...mapActions('pages', ['setPageData']),
		submit(key, index) {
			const {
				title,
				description,
			} = this.inputValues[key];

			this.setPageData({
				type: 'default',
				payload: {
					pageId: key,
					data: {
						meta: {
							title,
							description,
						},
					},
				},
			});
			this.inputValues = {
				...this.inputValues,
				[key]: {
					title,
					description,
					status: this.inputValues[key].title || this.inputValues[key].description ? this.$t('common.saved') : '',
				},
			};
			this.$v.inputValues[key].$reset();
			this.isOpen.splice(index, 1, !this.isOpen[index]);
		},
		reset() {
			// TODO: Confirmation modal
			this.inputValues = {
				...this.inputValues,
				[this.resetKey]: {
					title: this.getWebsiteMetaProperty(this.resetKey, 'title'),
					description: this.getWebsiteMetaProperty(this.resetKey, 'description'),
					status: this.inputValues[this.resetKey].status,
				},
			};
			this.$v.$reset();
		},
		// This is used redundantly in WebsiteSeo and in Geeneral comps. TODO: Reuse
		getWebsiteMetaProperty(pageId, key) {
			return this.defaultPages[pageId]?.meta?.[key] || this.website.meta?.[key] || '';
		},
	},
};
</script>

<style lang="scss" scoped>
.seo {
	&__container {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		@include mobile-view {
			flex-direction: column;
		}
	}

	&__info {
		width: 40%;
		margin-right: 40px;

		@include mobile-view {
			order: 2;
			width: 100%;
			margin-right: 0;
		}
	}

	&__meta {
		width: 60%;

		@include mobile-view {
			order: 1;
			width: 100%;
			margin-bottom: 32px;
		}

		&-footer {
			margin-right: 32px;

			@include mobile-view {
				margin-right: 24px;
			}
		}
	}
}
</style>
